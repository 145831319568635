import React from 'react'
import Script from 'next/script'
import useTmsConfig from '@/components/TmsScript/useTmsConfig'
import type { ValidTmsProductCodes } from '@/components/TmsScript/useTmsConfig'

export default function TmsScript({
  product
}: {
  product: ValidTmsProductCodes
}) {
  const tmsConfig = useTmsConfig(product)
  return (
    <Script
      id="pclntms-script"
      strategy="afterInteractive"
      src="/svcs/mkt/tag/pclntms.js"
      onLoad={() => {
        if (window.pclntms) {
          window.pclntms.push(tmsConfig)
        }
      }}
    />
  )
}
