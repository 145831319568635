import React from 'react'
import useBootstrapData from '@/hooks/useBootstrapData'
import useAppBanner from './useAppBanner'
import AppBanner from './AppBanner'

type AppDownloadBannerContentType = {
  titleText: string
  contentText: string
  pageId: string
}

function AppBannerWrapper({
  content
}: {
  content: AppDownloadBannerContentType
}) {
  const showAppBanner = useAppBanner()
  const {
    referral,
    webstats: { clientGUID }
  } = useBootstrapData()

  return showAppBanner ? (
    <AppBanner
      {...{
        referral: referral ?? {},
        clientGUID: clientGUID ?? '',
        ...content
      }}
    />
  ) : null
}

export default AppBannerWrapper
