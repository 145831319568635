import * as React from 'react'
import Footer from '@/components/Footer'
import Header from '@/components/Header'

type LayoutProps = {
  headerHTML: string
  footerHTML: string
  children: React.ReactNode
}

function Layout({ headerHTML, footerHTML, children }: LayoutProps) {
  return (
    <>
      <Header headerHTML={headerHTML} />
      {children}
      <Footer footerHTML={footerHTML} />
    </>
  )
}

export default Layout
