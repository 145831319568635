import React from 'react'
import createMarkup from '@/shared-utils/create-markup'

function Header({ headerHTML }: { headerHTML: string }) {
  return headerHTML.length > 0 ? (
    <div id="exp-hdr" className="experimental-header">
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createMarkup(headerHTML)}
        className="fixed"
        id="pcln-global-header"
      />
    </div>
  ) : null
}

export default Header
