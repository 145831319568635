const imageFolder =
  'https://s1.pclncdn.com/design-assets/next-landing/assets/images/cobrand/' as const

const affirm = {
  alt: 'Welcome Affirm member, thanks for choosing priceline',
  src: `${imageFolder}cobrand-affirm-promo.jpg?opto&auto=avif,webp`
} as const

const klarna = {
  alt: 'Welcome Klarna member, thanks for choosing priceline',
  src: `${imageFolder}cobrand-klarna-promo.jpg?opto&auto=avif,webp`
} as const

const cobrandkeys = [
  'co46157',
  'co2210202',
  'plchpcruises',
  'plc411',
  'plcruises',
  'plconly',
  'pllux',
  'co5464610',
  'co8191141',
  'co100011847',
  'co100187965',
  'co9252602',
  'co100136872',
  'co100688040'
] as const
const cobrands = {
  co46157: {
    alt: 'Welcome Rakuten member, thanks for choosing priceline',
    src: `${imageFolder}cobrand-rakuten-promo.jpg?opto&auto=avif,webp`
  },
  co2210202: {
    alt: 'Welcome RetailMeNot shopper, save on all your travel needs!',
    src: `${imageFolder}cobrand-retailmenot-promo.jpg?opto&auto=avif,webp`
  },
  plchpcruises: {
    alt: 'CheapCruises.com Priceline Express Deal',
    src: `${imageFolder}cobrand-cheap-cruises-promo.jpg?opto&auto=avif,webp`
  },
  plc411: {
    alt: 'Cruise411 Priceline Express Deals',
    src: `${imageFolder}cobrand-cruise411-promo.jpg?opto&auto=avif,webp`
  },
  plcruises: {
    alt: 'Cruises Priceline Express Deals',
    src: `${imageFolder}cobrand-cruises-com-promo.jpg?opto&auto=avif,webp`
  },
  plconly: {
    alt: 'CruisesOnly Priceline Express Deal',
    src: `${imageFolder}cobrand-cruises-only-promo.jpg?opto&auto=avif,webp`
  },
  pllux: {
    alt: 'Luxury Only Priceline Express Deal',
    src: `${imageFolder}cobrand-luxury-only-promo.jpg?opto&auto=avif,webp`
  },
  co5464610: {
    alt: 'Welcome TopCashback Member, earn cash back on your travel order',
    src: `${imageFolder}cobrand-topcashback-promo.jpg?opto&auto=avif,webp`
  },
  co8191141: affirm,
  co100011847: affirm,
  co100187965: affirm,
  co9252602: klarna,
  co100136872: klarna,
  co100688040: klarna
} as const

type CobrandsKeyType = keyof typeof cobrands

function isValidCobrandKey(
  normalizedRefId: string | null
): normalizedRefId is CobrandsKeyType {
  if (
    normalizedRefId &&
    cobrandkeys.includes(normalizedRefId as (typeof cobrandkeys)[number])
  ) {
    return true
  }
  return false
}

export default function getCobrandContent(refid: string | null) {
  const normalizedRefId = refid?.toLowerCase() ?? null
  return isValidCobrandKey(normalizedRefId) ? cobrands[normalizedRefId] : null
}
