import { useContext, useEffect } from 'react'
import pclnExperimentation from '@pcln/experimentation'
import ExperimentContext from '@/context/ExperimentContext'

export default function useSeti(
  experimentCode: string,
  shouldFireImpression = true
) {
  const { [experimentCode]: setiTest = 'DEFAULT' } =
    useContext(ExperimentContext)

  useEffect(() => {
    if (experimentCode && shouldFireImpression) {
      pclnExperimentation.fireImpression(experimentCode)
    }
  }, [experimentCode, shouldFireImpression])

  return setiTest
}
