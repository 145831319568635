import React from 'react'
import createMarkup from '@/shared-utils/create-markup'

function Footer({ footerHTML }: { footerHTML: string }) {
  const footerContent = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createMarkup(footerHTML)}
      id="pcln-global-footer"
    />
  )

  return footerHTML.length > 0 ? footerContent : null
}

export default Footer
