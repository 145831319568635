import React from 'react'
import Head from 'next/head'
import DEFAULT_META_TAGS from '@/constants/meta'
import type { MetaDescription, PageTitle } from '@/types'

type LinkTag = {
  href: string
  rel: string
  type?: string
}

const renderLinks = (linkTags: LinkTag[]) =>
  linkTags.map(linkTag => <link key={linkTag.href} {...linkTag} />)

const { image, url } = DEFAULT_META_TAGS
const favicons: LinkTag[] = [
  {
    rel: 'shortcut icon',
    href: '/favicon.ico',
    type: 'image/x-icon'
  }
]

const additionalLinks: LinkTag[] = [
  {
    rel: 'alternate',
    href: 'android-app://com.priceline.android.negotiator/pclnnegotiator/home'
  }
]

export default function Meta({
  description,
  pageTitle,
  canonicalUrl
}: {
  description: MetaDescription
  pageTitle: PageTitle
  canonicalUrl: string
}) {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@priceline" />
      <meta name="robots" content="noydir,noodp" />
      <meta name="language" content="english" />
      <meta name="description" className="description" content={description} />
      <meta
        name="google-site-verification"
        content="NfW_2qut5D0YEqJE2_8tqKYUKrWju1NsVFUc4Kxl7Sw"
      />
      <meta
        name="google-site-verification"
        content="F8oXNWFYF1zWWLC_bBMEiUaKq588GpL8o0r0r30sQtk"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=6.0, user-scalable=yes height=device-height"
      />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="-1" />
      <meta httpEquiv="Expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
      <meta
        httpEquiv="Cache-Control"
        content="no-store, no-cache, must-revalidate, post-check=0, pre-check=0"
      />
      <meta httpEquiv="Cache-Control" content="max-age=0" />
      {favicons && renderLinks(favicons)}
      <link rel="canonical" href={canonicalUrl} />
      {additionalLinks && renderLinks(additionalLinks)}
      <title>{pageTitle}</title>
    </Head>
  )
}
