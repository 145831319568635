import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { Box, Divider, Image } from 'pcln-design-system'
import useBootstrapData from '@/hooks/useBootstrapData'
import getCobrandContent from './constants'

const StyledImage = styled(Image)`
  margin: auto;
  padding-bottom: 8px;
  padding-top: 8px;
`

// Displays when users are navigated to Priceline through referral partners

export default function Cobrand() {
  const { referral: { id = '', sourceId = '' } = {} } = useBootstrapData()
  const { query } = useRouter()
  const refid = sourceId + id
  const promo = getCobrandContent(refid)

  return promo && query?.refid ? (
    <Box color="background.lightest" width={1}>
      <Divider borderColor="border.base" my={0} />
      <StyledImage alt={promo.alt} src={promo.src} />
    </Box>
  ) : null
}
