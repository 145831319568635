export default {
  image: 'https://www.priceline.com/static-pages/images/Artboard.png',
  url: 'https://www.priceline.com',
  description: {
    vacationpackages: `Save up to 625 dollars when you book your flight and hotel together! Vacation packages with exclusive deals on airline tickets & hotels worldwide. How much will you save?`,
    flights: `Looking to save on your next flight? We compare thousands of flight deals to get you there. Book a cheap flight today with free cancellation for more flexibility.`,
    hotels: `Get great deals on hotels, resorts and private rentals. Save even more on last minute deals and hotels nearby. Book a hotel for cheap today with free cancellation for more flexibility.`,
    vip: `Become a Priceline VIP member for free. Unlock huge savings, deals & travel perks. See what tier you qualify for today.`,
    home: 'Deep Discounts on Hotels, Flights and Rental Cars.  Get Exclusive Savings with Priceline.com.',
    join: 'Unlock huge savings, loyalty perks, and more when you become a Priceline VIP'
  },
  title: {
    flights: 'Flights: Find Cheap Flights & Airfares | Priceline',
    hotels: 'Hotels: Find Cheap Hotel Rooms & Discount Hotels | Priceline',
    nha: 'Hotels: Find Cheap Hotel Rooms & Discount Hotels | Priceline',
    vacationpackages:
      'Packages: Find bundle deals on a hotel, flight, & rental car vacation | Priceline',
    vip: 'Priceline VIP - Unlock savings, deals & travel perks | Priceline',
    dsarwebform: 'CCPA - Do Not Sell My Info Request | Priceline',
    'app-delete-account': 'Delete Account Request | Priceline',
    home: 'Priceline.com - The Best Deals on Hotels, Flights and Rental Cars.',
    join: 'Priceline.com - The Best Deals on Hotels, Flights and Rental Cars.'
  }
} as const
