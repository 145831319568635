import {
  FLIGHT_PRODUCT_TYPE,
  HOME_PRODUCT_TYPE,
  HOTEL_PRODUCT_TYPE
} from '@/constants/products'

const BANNER_TEXT_VALUES = {
  [FLIGHT_PRODUCT_TYPE]: {
    titleText: 'Get the app. Save more.',
    contentText: 'Save up to 40% on flights with the app',
    pageId: 'MWEB_FLT_HP_HEADER'
  },
  [HOME_PRODUCT_TYPE]: {
    titleText: 'Get the app. Save more.',
    contentText: 'Get exclusive deals only in our app',
    pageId: 'MWEB_HTL_HP_HEADER'
  },
  [HOTEL_PRODUCT_TYPE]: {
    titleText: 'Get the app. Save more.',
    contentText: 'Save up to 60% off hotels with the app',
    pageId: 'MWEB_HTL_HP_HEADER'
  }
} as const

export default BANNER_TEXT_VALUES
